@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Cedarville+Cursive&display=swap);
body {
  height: 100%; 
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

:root {
  --main-font: "Rubik", sans-serif;
  --sub-font: "Montserrat", sans-serif;
  --cursive: "Cedarville Cursive", cursive;
  --p-red: #ff4500;
  --p-pink: #e485ab;
  --p-grey: #7a6879;
  --p-sub-grey: #282225;
  --original-grey: rgb(96, 82, 95);
  --p-sub-pink: #e0719e;
  --p-yellow: #f6e04e;
  --p-blue: #aac6f9;
  --trans-grey: rgba(96, 82, 95, 0.8);
  --sub-font-size: 1.1em;
  --heading-font-size: 2.8em;
}

body {
  margin: auto;
  padding: 0;
  font-size: 0.83em;
  line-height: 1;
}

.NavButt {
  min-width: 7rem;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-align: center;
  margin: 1rem;
  border: none;
  color: white;
  font-size: 0.83em;
  background-color: #7a6879;
  background-color: var(--p-grey);
  font-family: "Montserrat", sans-serif;
  font-family: var(--sub-font);
  font-weight: bolder;
  border-radius: 20rem;
}
.NavButt:visited {
  text-decoration: none;
}
.NavButt:hover,
.NavButt:active,
.NavButt:focus {
  cursor: pointer;
  color: white;
  background-color: #e485ab;
  background-color: var(--p-pink);
  border-radius: 20rem;
}
.active {
  min-width: 7rem;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-align: center;
  margin: 1rem;
  font-family: "Montserrat", sans-serif;
  font-family: var(--sub-font);
  font-size: 0.83em;
  font-weight: bolder;
  cursor: pointer;
  color: white;
  background-color: #e485ab;
  background-color: var(--p-pink);
  border-radius: 20rem;
}
.section {
  position: relative;
  margin: 0 auto;
  height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}
.textContainer {
  margin: 0;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
  max-width: 70%;
  max-height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.headings {
  margin: 0;
  padding: 1rem;
  font-size: 2.8em;
  font-size: var(--heading-font-size);
  color: #7a6879;
  color: var(--p-grey);
  font-weight: bold;
  font-family: "Rubik", sans-serif;
  font-family: var(--main-font);
}
.faqsHeading {
  margin: 0;
  font-size: 1.5rem;
  color: #7a6879;
  color: var(--p-grey);
  font-weight: bold;
  font-family: "Rubik", sans-serif;
  font-family: var(--main-font);
}
.spanRed {
  color: #ff4500;
  color: var(--p-red);
}
.text {
  margin: 0;
  padding: 1rem;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-family: var(--sub-font);
  font-size: 1em;
  color: #282225;
  color: var(--p-sub-grey);
  line-height: 1.5;
  font-weight: bold;
}
.tagLine {
  font-family: "Cedarville Cursive", cursive;
  font-family: var(--cursive);
  color: black;
  font-size: 1.65em;
  font-weight: lighter;
}
.tab:hover > .plus {
  cursor: pointer;
  -webkit-filter: invert(1);
  filter: invert(1);
  transition: all 0.5s;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.downloads:visited {
  text-decoration: none;
}
.downloads:hover,
.downloads:active,
.downloads:focus {
  cursor: pointer;
  color: white;
  background-color: #e485ab;
  background-color: var(--p-pink);
  border-radius: 20rem;
}
.expandedSlider {
  object-fit: contain;
}
@-webkit-keyframes floater {
  from {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
  65% {
    -webkit-transform: translate(0, 7px);
            transform: translate(0, 7px);
  }
  to {
    -webkit-transform: translate(0, -0px);
            transform: translate(0, -0px);
  }
}

@keyframes floater {
  from {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
  65% {
    -webkit-transform: translate(0, 7px);
            transform: translate(0, 7px);
  }
  to {
    -webkit-transform: translate(0, -0px);
            transform: translate(0, -0px);
  }
}

.floater {
  -webkit-animation-name: floater;
  animation-name: floater;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
#placeholder::-webkit-input-placeholder {
  color: var(--sub-grey);
}

.modalTitle {
  display: block;
  color: rgb(96, 82, 95);
  color: var(--original-grey);
  padding: 0 1rem;
  font-size: 1.05em;
  font-family: "Rubik", sans-serif;
  font-family: var(--main-font);
  font-weight: lighter;
}

.modalText {
  padding: 0 0 0 1rem;
  font-family: "Montserrat", sans-serif;
  font-family: var(--sub-font);
  font-size: 0.9em;
  text-align: left;
  line-height: 1.6;
  font-weight: bold;
  color: black;
}
.formInput {
  display: block;
  border: 1.5px solid rgb(96, 82, 95);
  border: 1.5px solid var(--original-grey);
  border-radius: 2rem;
  width: 95%;
  padding: 0.5rem 1rem;
  margin: 1rem;
  font-family: "Montserrat", sans-serif;
  font-family: var(--sub-font);
  font-size: 0.9em;
  color: var(--sub-grey);
  background-color: white;
}

.redBorderFormInput {
  display: block;
  border: 2px solid #ff4500;
  border: 2px solid var(--p-red);
  border-radius: 2rem;
  width: 95%;
  padding: 0.5rem 1rem;
  margin: 1rem;
  font-family: "Montserrat", sans-serif;
  font-family: var(--sub-font);
  font-size: 0.9em;
  color: var(--sub-grey);
  background-color: white;
}
.redBorderFormInput:focus {
  outline: none;
}
.formInput:focus {
  outline: none;
}
.formInput:hover {
  background-color: #ed8cbb4d;
}

.formSelect {
  display: block;
  padding: 2px;
  color: var(--sub-grey);
}
.formSelect:hover {
  background-color: #e485ab;
  background-color: var(--p-pink);
  color: white;
}
.formTextArea {
  min-height: 20%;
  max-width: 95%;
  padding: 1rem;
}
.clickableSmall:hover {
  cursor: pointer;
  color: #e485ab;
  color: var(--p-pink);
}

.dark::-webkit-input-placeholder {
  color: var(--sub-grey);
  font-size: 1em;
}

.dark:-ms-input-placeholder {
  color: var(--sub-grey);
  font-size: 1em;
}

.dark::-ms-input-placeholder {
  color: var(--sub-grey);
  font-size: 1em;
}

.dark::placeholder {
  color: var(--sub-grey);
  font-size: 1em;
}
.redBorder {
  border-color: #ff4500;
  border-color: var(--p-red);
}
input[type="checkbox"]:checked {
  outline: thick;
  -webkit-filter: brightness(0) saturate(100%) invert(84%) sepia(30%)
    saturate(2078%) hue-rotate(286deg) brightness(98%) contrast(89%);
  filter: brightness(0) saturate(100%) invert(84%) sepia(30%) saturate(2078%)
    hue-rotate(286deg) brightness(98%) contrast(89%);
}
input[type="password"] {
  font-family: Arial, Helvetica, sans-serif;
}
.formStyle {
  overflow-y: scroll;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem 3.5rem;
}
.red {
  color: #ff4500;
  color: var(--p-red);
  margin-bottom: 0.5rem;
  font-size: 1em;
}

.icons {
  width: 16px;
  margin: 5px;
}
.icons:hover {
  cursor: pointer;
}
.status {
  color: white;
  font-family: "Rubik", sans-serif;
  font-family: var(--main-font);
  text-align: center;
  width: 75px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 0.85em;
  margin: 0.3rem;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 700;
  margin: 0 auto;
  padding: 1.5rem 3.5rem;
  width: 100%;
  max-height: 20%;
  background-color: white;
}
.notification {
  position: relative;
  top: -10px;
  right: 30px;
  padding: 5px 9px;
  border-radius: 50%;
  background: #ff4500;
  background: var(--p-red);
  color: white;
  font-family: "Rubik", sans-serif;
  font-family: var(--main-font);
}

.dashBoardBtns {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 3rem;
  padding: 0.5rem;
  border-radius: 2rem;
  border: 0.5px solid rgb(96, 82, 95);
  border: 0.5px solid var(--original-grey);
  box-shadow: 0px 10px 10px 0px rgba(50, 50, 50, 0.2);
  margin: 1rem 0;
  cursor: pointer;
}

.dashBoardBtns:hover {
  cursor: pointer;
  background-color: #e485ab;
  background-color: var(--p-pink);
  color: white;
}

.dashBoardBtns:hover > .dashBoardIcons {
  cursor: pointer;
  -webkit-filter: invert(1);
  filter: invert(1);
}
.locationSuggestsContainer {
  width: 80%;
}
.locationSuggests {
  list-style: none;
}
.locationSuggestItem {
  cursor: pointer;
}
.locationSuggestItem:hover {
  cursor: pointer;
  background-color: rgba(237, 140, 187, 0.3);
  border-radius: 2rem;
}
.hidden {
  display: none;
}

.bubble {
  margin: 0.2rem;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 1rem;
  padding: 1rem;
  display: grid;
  grid-template: 15px auto / 20% 80%;
  grid-column-gap: 5px;
}

.policy {
  overflow-y: scroll;
  padding: 1rem;
  max-height: 350px;
}

.link:hover {
  cursor: pointer;
}
.footerLinks,
.footerLinks:visited,
.footerLinks:hover,
.footerLinks:active .footerLinks:focus {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-family: var(--sub-font);
  color: white;
  font-size: 0.85em;
  font-weight: bold;
  margin: 5px 10px;
}

.itemContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 650px;
  height: 8.5rem;
  margin: 1rem;
  padding: 20px;
  border: 1px solid rgba(50, 50, 50, 0.05);
  border-radius: 0.5rem;
  box-shadow: 0px 15px 15px 5px rgba(50, 50, 50, 0.3);
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexCol {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.notification {
  position: "absolute";
  top: 0;
  right: 0;
  z-index: 1000;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: red;
}

.toolTip {
  display: inline;
  position: relative; /* making the .tooltip span a container for the tooltip text */
  cursor: pointer;
  z-index: 500;
}

.toolTip:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;
  bottom: 100%;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  /* move to right */
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  /* basic styles */
  width: 50px;
  padding: 10px;
  border-radius: 10px;
  background: #7a6879;
  background: var(--p-grey);
  color: white;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-family: var(--main-font);
  font-size: 0.8em;
  display: none; /* hide by default */
}
.toolTip:after {
  content: "";
  position: absolute;
  /* position tooltip correctly */
  left: 0%;
  margin-left: 5px;
  /* vertically center */
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  /* the arrow */
  border: 10px solid #000;
  border-color: #7a6879 transparent transparent transparent;
  border-color: var(--p-grey) transparent transparent transparent;
  display: none;
}
.toolTip:hover:before,
.toolTip:hover:after {
  display: inline;
  z-index: 500;
}
@media screen and (max-width: 850px) {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  .formStyle {
    padding: 0.5rem;
  }
  .tagLine {
    display: inline-block;
    text-align: center;
  }
  .headings {
    text-align: center;
  }

  .dashBoardBtns {
    position: relative;
    flex-flow: column;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: white;
  }

  .itemContainer {
    max-width: 350px;
  }
  .policy {
    max-height: 250px;
  }
}
.fadeInAnimation {
  -webkit-animation: fadingIn ease 2s;
  animation: fadingIn ease 2s;
}

@-webkit-keyframes fadingIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadingIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Spinner_loader__fTuX5,
.Spinner_loader__fTuX5:before,
.Spinner_loader__fTuX5:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: Spinner_load7__3lEXz 1.8s infinite ease-in-out;
  animation: Spinner_load7__3lEXz 1.8s infinite ease-in-out;
}
.Spinner_loader__fTuX5 {
  color: #ed8bbb;
  font-size: 10px;
  margin: 25% auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.Spinner_loader__fTuX5:before,
.Spinner_loader__fTuX5:after {
  content: "";
  position: absolute;
  top: 0;
}
.Spinner_loader__fTuX5:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.Spinner_loader__fTuX5:after {
  left: 3.5em;
}
@-webkit-keyframes Spinner_load7__3lEXz {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes Spinner_load7__3lEXz {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

